
import Vue from 'vue'
import RPHFooterNav from 'rph/molecules/RPHFooterNav/index.vue'
import RPHFooterSocials from 'rph/molecules/RPHFooterSocials/index.vue'
import RPHPayments from 'rph/molecules/RPHPayments/index.vue'
import RPHFooterContacts from 'rph/molecules/RPHFooterContacts/index.vue'
import RocketIcon from '~/assets/images/company-logo.svg?inline'

export default Vue.extend({
  name: 'RPHFooter',
  components: { RPHFooterNav, RPHFooterSocials, RPHPayments, RocketIcon, RPHFooterContacts },
  props: {},
  data() {
    return {}
  },
  head() {
    return { script: [{ json: this.structuredData, type: 'application/ld+json' }] }
  },
  computed: {
    companyEmail() {
      return (
        this.settings.find((setting) => setting.attributes.param === 'order_email_support')
          ?.attributes?.value || ''
      )
    },
    companyPhone() {
      return (
        this.settings.find((setting) => setting.attributes.param === 'contact-phone-number')
          ?.attributes?.value || ''
      )
    },
    companyAddress() {
      return (
        this.settings.find((setting) => setting.attributes.param === 'company_address')?.attributes
          ?.value || ''
      )
    },
    companyLlp() {
      return (
        this.settings.find((setting) => setting.attributes.param === 'company_llp')?.attributes
          ?.value || ''
      )
    },
    companyBin() {
      return (
        this.settings.find((setting) => setting.attributes.param === 'company_bin')?.attributes
          ?.value || ''
      )
    },
    settings() {
      return this.$store.getters['rph-footer/settings'] || []
    },
    copyright() {
      return this.$store.getters['rph-footer/copyright']
    },
    structuredData() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Pharmacy',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Алматы',
          addressCountry: 'Казахстан',
        },
        openingHours: 'пн-вс 10.00-19.00',
        image: `https://aurma.kz/rph-logo.svg`,
      }
    },
    workHours() {
      return this.$store.getters['rph-footer/workHours']
    },
  },
  methods: {},
})
